@import "../../../styles/variable";

.preview {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid gray;
  overflow: hidden;
  margin-right: 10px;
  min-width: 140px;
  position: relative;
  margin-bottom: 10px;
  width: 140px;
  height: 140px;

  * {
    transition: 0.3s linear all;
  }

  .close {
    position: absolute;
    right: 0px;
    top: 0px;
  }

  img {
    height: 100%;
    cursor: pointer;

    &:hover {
      transform: scale(1.2);
    }
  }
}

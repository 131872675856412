@import "../../../../styles/variable";

.map-store {
  width: 100%;
  height: 100vh;
}

.fab-icon-add {
  position: absolute;
  padding: 40px 60px;
  bottom: 0;
  right: 0;
}

.help-area {
  &.add-poi {
    font-weight: 100;
    top: 160px;

  }

  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 20px;
  color: $primary-blue-2;
  letter-spacing: 0;
  text-align: center;
  line-height: 20px;
  padding: 20px;
  z-index: 10;
  background: white;
}

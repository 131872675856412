$gray: #f6f7fb;
$gray3: #808080;
$gray1: #5f6675;
$gray2: #707070;
$gray223: rgba(255, 255, 255, 0.53);
$gray41: #898c9b;
$gray2: #747986;
$black: #1f1f20;
$gray412: #a8b3c2;
$back22: #91a0bf;
$back2: #E88320;
$orange: #E88320;
$orange1: #e3d9d9;
$orange2: #D6C636;
$orange3: #EA7E2C;
$green: #228B22 ;
$red: #E3244B;
$red1: #F08080;
$back: #5a5379;
$back-33: #9C9D5D;
$fontProxima: "AvenirNextCyr-Regular";
$fontProximaBold: "AvenirNextBold";
$fontProximaSemiBold: "AvenirNext";
$fontProximaMed: "AvenirNextCyr-Medium";
$font17: 17px;

$blue-0: rgba(0, 201, 255, 0.94);
$blue: #bfc0f4;
$blue-op: rgba(44, 194, 234, 0.37);
//$blue-5: #a4c5e6;
$blue-5: $blue-0;
$blue-1: #72a4ff;
$blue-2: #8b72ff;
$blue-322: #9c63f1;
$blue-3: #1bd2d4;
$mobile-width: 833px;

$primary-blue: #7f8fab;
$primary-blue-4: #F4F5F8;
$primary-blue-1: #022037;
$primary-blue-3: #3f51b5;
$primary-blue-2: rgba(2, 32, 55, 0.51);

$background: #282c34;
$background-middle: #333842;
$background-light: #3c4156;
$background-hover: #7f8fab;
$text-color: #fff;

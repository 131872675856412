@import "../../../styles/variable";

.main-layout {

  width: 100%;
  height: 100%;
  background-color: $gray;

  .part-container {
    width: calc(100vw - 308px);
    height: 100vh;
    float: left;
    padding-top: 0;
  }
}

.radio-group {
  span {
    font-size: 10px;
  }
}

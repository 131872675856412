@import "../../styles/variable";

.upload-files {

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;

}

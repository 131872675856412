@import "../../styles/variable";

.tooltip {
  position: absolute;
  z-index: 9999;
  display: inline-block;
  pointer-events: none;
  //border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  //visibility: hidden;
  max-width: 220px;
  padding: 5px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: -5px;
  //right: 110%;
  left: 100%;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent black;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

@import "./variable";
@import "./modals";
@import "./margins";
@import "node_modules/react-image-gallery/styles/scss/image-gallery.scss";

.text-center {
  text-align: center;
  margin: auto;
}

.Mui-checked {
  .text-overflow {
    font-family: $fontProximaBold;
  }
}

.MuiDivider-root {
  background-color: rgba(255, 255, 255, 0.7) !important;
}

.MuiToolbar-root {
  background-color: $background !important;
}

.MuiList-root {
  background-color: $background !important;

  .MuiListItem-root:hover {
    background-color: $background-hover !important;
  }
}

.MuiTabs-scrollButtons {
  width: 25px !important;
}

.MuiTabs-scroller {
  background-color: $background !important;

  span {
    color: $text-color;
  }

  .MuiTabs-indicator {
    background-color: $primary-blue !important;
  }
}

.map-store {
  .gm-fullscreen-control {
    margin-top: 150px !important;
  }

  div[dir="ltr"] {
    margin-top: 150px !important;
  }

  .gmnoprint {
    .gm-svpc {
      margin: 0 !important;
    }
  }

}

.list-view {
  overflow: auto;
  max-height: 40vh;
  width: 100%;

}

h4, p {
  margin: 0;
}

img[src^='/assets/img/poi.png?icon=1'] {
  //transform: translate(16px, 0px);
}


img[src^='/assets/img/Pole.png'] {

  //transform: translate(7.5px,0px);
}

@for $i from 0 to 100 {

  img[src^='http://www.google.com/mapfiles/marker.png?image=#{$i}&i=#{$i}'] {
    opacity: 0.5;
  }

  img[src^='http://www.google.com/mapfiles/marker.png?image=#{$i}&i=-1'] {
    opacity: 1;
  }

  img[src^='http://www.google.com/mapfiles/marker.png?image=#{$i}&i=-2'] {
    opacity: 0.7;
  }
}

.text-overflow {
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.material-icons {
  color: $primary-blue-1;
}

.my-checkbox {

  &.checked {
    svg {
      path {
        //fill: white;
      }
    }
  }
}

.link {
  cursor: pointer;

  &:hover {
    //text-decoration: underline;
    //border-bottom: 1px solid $back;
  }
}

.m-0 {
  margin: 0 !important;
}

.p-4 {
  margin: 8px;
}

.highlight {
  margin: 0 !important;
  padding: 0 !important;
  color: white;
  background-color: $orange;
}

a {
  color: $blue;
  text-decoration: none;
  cursor: pointer;

  &.__link {
    color: #0000FF;
    text-decoration: underline;
  }

  &.link {
    display: inline-block;
    position: relative;

    &:after {
      content: "";
      height: 2px;
      position: absolute;
      width: 100%;
      left: 0px;
      bottom: -5px;
      transition: all 250ms ease 0s;
      -webkit-transform: scale(0);
      transform: scale(0);
      transform-origin: left;
      background: $blue none repeat scroll 0% 0%;
    }

    &:hover {
      &:after {
        transform: scale(1);
      }
    }
  }

}

.card-title {
  a {
    &.link {

      &:hover {
        &:after {
          background: $blue none repeat scroll 0% 0%;
        }
      }
    }

  }
}

span {
  text-overflow: ellipsis;
  overflow: hidden;
}

.disabled {
  pointer-events: none;
  opacity: 0.3;
}


::-webkit-scrollbar-track {
  //border: 1px solid black;
  border-radius: 5px;
  background-color: rgba(245, 245, 245, 0);
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: rgba(245, 245, 245, 0);
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  background-color: $gray2;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: $gray1;
  }

}

.MuiFormControl-root {
  label, input, .MuiInputBase-inputSelect {
    color: $primary-blue-1;
  }

  label {
    z-index: 2;
  }

  input {
    background: $primary-blue-4;
  }
}

::placeholder,
input::placeholder,
::-webkit-input-placeholder,
::-moz-placeholder,
:-ms-input-placeholder,
:-moz-placeholder,
input {
  font-weight: 100 !important;
  font-family: $fontProxima;
}

.relative {
  position: relative;
}

.modal {
  .content {
    font-size: 18px;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
  }
}

svg {
  cursor: pointer;

  &:hover {

  }
}

.popup-container {
  cursor: pointer !important;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: $text-color !important;
}

::-moz-placeholder { /* Firefox 19+ */
  color: $text-color !important;
}

:-ms-input-placeholder { /* IE 10+ */
  color: $text-color !important;
}

:-moz-placeholder { /* Firefox 18- */
  color: $text-color !important;
}

.popup-bubble {
  b {
    font-size: 18px;
  }

  span {
    font-size: 16px;
  }
}

@import "./variable";

.dialog-view {
}

.modal-container {
  position: relative;
  padding: 40px;
  background-color: $background;

  .modal-title {
    font-size: 20px;
    color: $text-color;
    letter-spacing: 0;
    text-align: center;
    line-height: 20px;
    font-weight: 100;
  }

  .modal-body {
    max-height: 50vh;
    overflow: auto;
    margin-top: 20px;

    .modal-option {
      opacity: 0.5;
      font-size: 16px;
      color: $text-color;
      letter-spacing: 0;
      text-align: center;
      line-height: 20px;
      padding: 10px;
      cursor: pointer;

      &:hover, &.active {
        opacity: 1;
        font-family: $fontProximaBold;
      }
    }
  }

  .close {
    position: absolute;
    right: 20px;
    top: 0;
    z-index: 1;
  }
}

div[role="dialog"] {
  .MuiBackdrop-root {
    background: rgba(14, 34, 58, 0.51);
  }

  .MuiPaper-root {
    box-shadow: 0 20px 60px 0 rgba(24, 26, 43, 0.10);
    border-radius: 0;

    .modal-container {
      padding: 40px;

      .close {
        margin: 20px;
        opacity: 0.5;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .MuiDialogTitle-root {
    padding: 20px;
  }

  .MuiDialogContent-root {
    padding: 0 40px;
    background-color: $background !important;

    .MuiFormLabel-root {
      color: $text-color;
    }

    .MuiInputBase-input {
      background: none !important;
      color: $text-color;
    }

    .MuiInput-underline:before {
      border-bottom: 1px solid #fff !important;
    }

    .MuiSvgIcon-root {
      color: $text-color !important;
    }
  }

  .MuiDialogActions-root {
    padding: 20px;
    background-color: $background !important;

    .relative {
      opacity: 0.75;
      color: $text-color;

      &:hover {
        opacity: 1;
      }
    }

    .my-btn {
      min-width: 130px;
      text-align: center;
      border-radius: 2px;
    }
  }
}

@import "../../../../styles/variable";

.main-area {
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: -1;
  background-color: $background-middle;
}

.gmnoprint {
  margin-top: 150px !important;
}

.view-mode {
  opacity: 0.5;
  padding: 10px;

  &.view-selected {
    opacity: 1;

  }
}

.open-sidebar {
  position: absolute;
  left: -120px;
  //padding: 10px;
  background: $background;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    padding: 0;
  }
}

header {
  top: 20px !important;
  right: 60px !important;
  max-height: 80px !important;
  height: 80px !important;
  //width: calc(100% - 490px) !important;
  background-color: white !important;
  display: flex;
  justify-content: center;
}

.main-menu {
  div[role="document"] {
    border-radius: 0;
    margin-top: 48px;
    margin-left: 24px;
    width: 206px;
    background-color: $background;
  }

  ul {
    li {
      opacity: 0.5;
      padding-left: 38px;
      color: $text-color;

      &:hover {
        background: none;
        opacity: 1;
      }
    }
  }
}

.mode-view {
  display: flex;
  align-items: center;
  //padding: 0 20px;

  * {
    display: flex;
    align-items: center;
  }
}

.selected {
  background: $primary-blue;
  color: white;
}

.menu-arrow-icon {
  padding: 0 10px;
  transform-origin: center;
  margin-left: 5px;

  * {
    color: $text-color;
  }
}

.search-input {
  svg {
    width: 34px;
    height: 34px;
  }

  input {
    border: none;
    font-size: 16px;
    padding: 10px;
    outline: none;

    &:focus {
      border: none;
      outline: none;
    }
  }
}

.logo {
  height: 0;

  &.full-img {
    width: 50px;
    height: inherit;
  }
}

.logo-img {
  padding: 0 !important;

  img {
    width: 40px;
    border-radius: 50%;
    height: 40px;
  }
}

.help-info {
  padding-top: 10px;
  padding-bottom: 10px;

  .help-info-container {
    padding-left: 30px;
    border-left: 1px solid #EEEEEE;

  }
}

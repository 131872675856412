@import "../../../../styles/variable";

.entities-info {
  padding: 20px 0;


  .radio-item {
    .option-available {
      opacity: 0.5;
      color: $text-color;
    }

    &.checked {
      .option-available {
        opacity: 1;
      }
    }
  }
}

@import "../../../../styles/variable";

.entities-info {
  padding: 20px 0;

  label{
    margin: 0!important;
    span{
      font-size: 14px!important;
      color: $text-color;
    }
  }
}
.svg-icon {
  svg {
    max-width: 24px;
    max-height: 24px;
  }
}

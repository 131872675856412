@import "variable";

.fab-icon-add {
  button{
    background: $primary-blue;
  }
}

.MuiButton-contained:hover {
  background-color: $black !important;
}

.my-btn {
  position: relative;
  margin: auto;
  color: white;
  border: 0;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 10px 16px !important;
  font-size: 19px;
  font-weight: 400;
  border-radius: 4px;
  background-color: #a8b3c2;

  &.btn-primary {
    background-color: $primary-blue;
    color: white;
    font-weight: 900;
    border-radius: 2px;
  }

  &.def-btn {
    background-color: white;
    border-radius: 5px;
    border: 1px solid #C0C0C0;
    color: #7B68EE;
  }

  &.my-primary {
    background-color: $back;
  }

  &.my-success {
    background-color: $blue-3;
  }

  &.pop-up-btn {
    padding: 10px 20px !important;;
    color: white;
    background: $gray2;
    border-radius: 0px !important;
  }


  svg {
    width: 17px;

    path {
      //fill: $gray223 !important;
    }
  }
}

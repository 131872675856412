@import "variable";

.main-view {
  padding-top: 180px;
}

.p-24{
  padding: 24px!important;
}
.pr-12 {
  padding-right: 12px!important;
}

.pl-12 {
  padding-left: 12px!important;
}
.MuiSlider-root{

  overflow: visible;
  padding-top: 40px!important;
  span{
    overflow: visible;
  }
}
.pr-0 {
  padding-right: 0 !important;
}

.text-light {
  opacity: 0.5;
  font-size: 14px;
  color: $text-color;
  letter-spacing: 0;
  line-height: 20px;
}

/* For desktop: */
.col-1 {
  width: 8.33%;
}

.col-2 {
  width: 16.66%;
}

.col-3 {
  width: 25%;
}

.col-4 {
  width: 33.33%;
}

.col-5 {
  width: 41.66%;
}

.col-6 {
  width: 50%;
}

.col-7 {
  width: 58.33%;
}

.col-8 {
  width: 66.66%;
}

.col-9 {
  width: 75%;
}

.col-10 {
  width: 83.33%;
}

.col-11 {
  width: 91.66%;
}

.col-12 {
  width: 100%;
}

.text-left {
  text-align: left;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.svg-icon {
  margin: 0 5px;

  * {
    display: flex;
    align-items: center;
  }
}

.d-flex {
  display: flex;

  &.f-a {
    flex: initial !important;

    * {
      flex: initial !important;
    }
  }

  &.a-c {
    align-items: center;
  }

  &.a-b {
    align-items: flex-end;
  }

  &.j-a {
    justify-content: space-between;
  }

  &.j-btw {
    justify-content: space-between;
  }

  &.j-around {
    justify-content: space-around;
  }

  &.j-center {
    justify-content: center;
  }

  &.j-start {
    justify-content: flex-start;
  }

  &.j-end {
    justify-content: flex-end;
  }

  &.f-row {
    flex-direction: row;
  }

  &.f-col {
    flex-direction: column;
  }

  &.f-col-rev {
    flex-direction: column-reverse;
  }

  &.wrap {
    flex-wrap: wrap;
  }
}

.text-left {
  text-align: left;
}

.fullWidth {
  width: 100%;
}

.fullHeight {
  height: 100vh;
}


.p-2 {
  padding: 20px;
}

@media only screen and (max-width: $mobile-width) {
  /* For mobile phones: */
  [class*="col-"] {
    width: 100%;
  }
}

.text-blue {
  color: $blue;
}

.text-orange {
  color: $back2;
}

.text-overflow {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  display: block;
}

.text-green {
  color: $green;
}

.text-red {
  color: $red !important;
}

.back-red {
  background: $red1;
}

.back-green {
  background: $green;
}

.back-brown {
  background: $orange;
}

.text-center {
  text-align: center;
  justify-content: center;
}

.text-font-24 {
  line-height: 24px;

}

.text-disabled {
  pointer-events: none;
}

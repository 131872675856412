@import "../../../styles/variable";

label[for='email'], label[for='password'] {
  opacity: 0;
}

label[data-shrink='true'] {
  opacity: 1;
}

label[data-shrink='true'] + div #email::placeholder,
label[data-shrink='true'] + div #password::placeholder
{
  opacity: 0 !important;
}

#email::placeholder, #password::placeholder {
  color: $text-color  !important;
  opacity: 0.75 !important;
}

.login-page {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  .help-area {
    background: $blue-322;
  }

  .login-title {
    color: #56677e;
    font-size: 45px;
    margin-bottom: 40px;

    letter-spacing: 2.25px;

    span {
      font-family: $fontProximaBold;
      font-weight: 600;
    }
  }

  .login-form-body {
    min-width: 360px;

    .form-item {
      margin-bottom: 10px;

      label {
        color: #56677e;
        font-size: 20px;
        font-weight: 400;
        letter-spacing: 1px;
        margin: 15px 0;
      }

      input {
        border-radius: 6px;
        border: 1px solid #56677e;
        padding: 10px;
        font-size: 20px;
      }
    }

    .form-actions {
      .my-btn {
        margin: 0;
        margin-right: 10px;
      }

      a {
        color: #5a5379;
        text-decoration: none;
      }
    }
  }

  .form-container {
    padding: 40px;

    form {
      min-width: 360px;
      width: 30vw;
      border-bottom: 1px solid $gray412;
      padding-bottom: 40px;
    }
    .error-message {
      color: red;
      width: 90%;
      margin-top: 10px;
    }
  }

  .new-here {
    font-size: 18px;
    font-weight: 400;
    color: #a8b3c2;
    padding: 40px 0;

  }

  .terms {
    color: #b2b2b2;
    font-size: 12px;
    font-weight: 400;
    position: absolute;
    left: 40px;
    bottom: 40px;
  }

  .col-6 {
    position: relative;

  }
  .main-logo{
    position: absolute;
    left: 50%;
    top:50%;
    transform: translate(-50%, -50%);
    img{
      position: absolute;
      left: 50%;
      top:50%;
      transform: translate(-50%, -50%);
      opacity: 0;
    }
  }
}

.first-page{
  background: $background;
  height: 100vh;
  .main-page-view{
    min-width: 440px;
    .logo{
      width: 270px;
      height: 35px;
      opacity: 0;
    }
    input {
      background: $background;
      color: $text-color !important;
    }

    label {
      color: $text-color !important;
    }

    .MuiInput-underline {
      &:before {
        border-bottom: 1px solid $text-color !important;
      }
      &:after {
        border-bottom: 2px solid $text-color !important;
      }
    }

    .link{
      margin: 20px 0;
      opacity: 0.5;
      font-family: $fontProxima;
      font-size: 16px;
      color: $text-color;
      letter-spacing: 0;
      line-height: 20px;
    }
    .title{
      padding: 20px 0;
      opacity: 0.5;
      font-family: $fontProxima;
      font-size: 20px;
      color: $text-color;
      letter-spacing: 0;
      text-align: center;
      line-height: 20px;
    }
  }
}

@import "../../../../styles/variable";

.project-info {
  padding: 20px 0;

  .selected-project {
    font-size: 16px;
    color: $text-color;
    letter-spacing: 0;
    line-height: 20px;
    max-width: 190px;
  }
}

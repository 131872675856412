@import "../../styles/variable";
.table-view{

  table{
    thead{
      th{
        *{
          font-family: $fontProximaBold!important;
        }
        text-transform: uppercase;
        font-size: 16px;
        background: $background;
        input{
          background: $background;
          color: $text-color;
        }

        .MuiInput-underline {
          &:before {
            border-bottom: 1px solid $text-color !important;
          }
          &:after {
            border-bottom: 2px solid $text-color !important;
          }
        }

        span {
          color: $text-color;
        }
      }
    }
    tbody{
      background-color: $background-middle;
      tr{
        &:hover{
          background: $background-hover;
        }

        span {
          color: $text-color;
        }

        big {
          color: $text-color;
        }
      }
    }
  }
}
